import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AdminUserService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/user';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    all() {
        let url = `${this.#api}/get/all`;
        return apiService.get(url);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id,data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url,data)
    }

    delete(adminUserId) {
        let url = `${this.#api}/${adminUserId}`
        return apiService.delete(url)
    }

    updatePassword(id, data){
        let url = `${this.#api}/${id}/change-password`
        return apiService.put(url,data)
    }

    getAllAdminUser(userType,score_id){
        let url = `${this.#api}/get/filter-user?userType=${userType}`;
        if (score_id != null)
            url = `${url}&score=${score_id}`;
        return apiService.query(url);
    }

    getCurrentUserEncryptedId(){
        let url = `${this.#api}/get-encrypted-id`
        return apiService.get(url)
    }

    search(data={}){
        let url = `${this.#api}/search`;
        let param = {params: data};
        return apiService.query(url, param);
    }
}
