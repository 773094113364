import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class userService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}admin`;
    }

    requestMfaAuthenticator() {
        let url = `${this.#api}/mfa-authenticator`
        return apiService.get(url);
    }

    activateMfaAuthenticator(data) {
        let url = `${this.#api}/activate/mfa-authenticator`
        return apiService.post(url, data);
    }
    deActivateMfaAuthenticator() {
        let url = `${this.#api}/deactivate/mfa-authenticator`
        return apiService.post(url);
    }


    checkVerificationEnabled(data) {
        let url = `${this.#api}/check/verification-enabled`
        return apiService.post(url, data);
    }


    verifyMfaVerificationCode(data) {
        let url = `${this.#api}/verify/mfa-verification-code`
        return apiService.post(url, data);
    }

    verifyEmailVerificationCode(data) {
        let url = `${this.#api}/verify/email-verification-code`
        return apiService.post(url, data);
    }
    sendMobileVerificationCode() {
        let url = `${this.#api}/send/verification-code`
        return apiService.get(url);
    }
    getStats() {
        let url = `${this.#api}/stats`
        return apiService.get(url);
    }
    verifyMobile(data) {
        let url = `${this.#api}/verify/mobile`
        return apiService.post(url, data);
    }

    register(data) {
        let url = `${this.#api}/register`
        return apiService.post(url, data);
    }

    update(id,data) {
        let url = `${this.#api}/user/${id}/update`
        return apiService.post(url, data);
    }

    activateEmailVerificationCode() {
        let url = `${this.#api}/activate/email-authenticator`
        return apiService.post(url);
    }

    deActivateEmailVerificationCode(data) {
        let url = `${this.#api}/deactivate/email-authenticator`
        return apiService.post(url, data);
    }

    createByAdmin(data) {
        let url = `${this.#api}/create-user/by-admin`
        return apiService.post(url, data);
    }

    paginate(data,index) {
        let url = `${this.#api}/user`
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
            if (index)
                url = url + "&page=" + index
        } else {
            url = url + "?page=" + index
        }

        return apiService.get(url);

    }
    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/user/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    logged() {
        let url = `${this.#api}/is/logged`
        return apiService.get(url);
    }

    requestEmailVerificationCode(data) {
        let url = API_URL + 'admin/request/verification-code'
        return apiService.post(url, data);
    }

    requesRegistrationEmailVerificationCode(data) {
        let url = API_URL + 'user/resend/register/verification'
        return apiService.post(url, data);
    }

    getTeachers() {
        let url = `${this.#api}/teacher/all`
        return apiService.get(url);
    }

    assignRole(id, data) {
        let url = `${this.#api}/${id}/assign-role`
        return apiService.post(url, data);
    }
    removeRole(id, data) {
        let url = `${this.#api}/${id}/remove-role`
        return apiService.post(url, data);
    }
    userRoles(id) {
        let url = `${this.#api}/${id}/roles`
        return apiService.get(url);
    }

    getStudentsByAcademicYearProgramAndSemester(academicYearId,programId,gradeId)
    {
        let url = `${this.#api}/student/${academicYearId}/program/${programId}/semester/${gradeId}`
        return apiService.get(url);
    }

    importUser(data){
        let url = `${this.#api}/import-student`;
        return apiService.post(url, data);
    }

    getAllUserByIds(data){
        let url = `${this.#api}/user/selected/all`;
        return apiService.post(url, data);
    }

    search(data={}){
        let url = `${this.#api}/search`;
        let param = {params: data};
        return apiService.query(url, param);
    }
}
