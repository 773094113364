<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="600"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} profile</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="6" md="12">
              <v-text-field label="First name*"
                  outlined dense v-model="user.first_name" :error="$v.user.first_name.$error">
              </v-text-field>
              <span class="text-danger" v-if="$v.user.first_name.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-text-field label="Middle name"
                            outlined dense v-model="user.middle_name">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-text-field label="Surname" :error="$v.user.last_name.$error"
                            outlined dense v-model="user.last_name">
              </v-text-field>
              <span class="text-danger" v-if="$v.user.last_name.$error">This information is required</span>

            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-text-field label="Mobile"
                            outlined dense v-model="user.mobile">
              </v-text-field>


            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-text-field label="Phone"
                            outlined dense v-model="user.phone">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-text-field label="Address" :error="$v.user.address.$error"
                            outlined dense v-model="user.address">
              </v-text-field>
              <span class="text-danger" v-if="errors.address" >**{{errors.address[0]}}</span>
              <span class="text-danger" v-if="$v.user.address.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="6" md="12">
              <v-text-field label="Username" :error="$v.user.username.$error"
                            outlined dense v-model="user.username">
              </v-text-field>
              <span class="text-danger" v-if="$v.user.username.$error">This information is required</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            color="btn btn-primary"
            dark
            x-large
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import AdminUserService from "../../../../services/admin-user/AdminUserService";
const admin_user = new AdminUserService();
export default {
  validations: {
    user:{
      first_name: {required},
      last_name: {required},
      address: {required},
      username: {required},
    },

  },
  data() {
    return {
      dialog: false,
      title:'',
      loading:false,
      edit:false,
      user:{
        first_name:'',
        middle_name:'',
        last_name:'',
        mobile:'',
        phone:'',
        address:'',
        username:'',
      },
      errors: [],
    }
  },
  methods:{
    closeDialog(){
      this.dialog = false;
     /* this.resetForm();*/
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    editProfile(item) {
      this.openDialog();
      this.edit = true;
      this.user = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
          this.update();
      }
    },
    update: function () {
      this.loading = true;
      admin_user
          .update(this.user.id, this.user)
          .then(response => {
            this.$snotify.success("Profile updated");
            this.resetForm();
            this.closeDialog();
            this.loading = false;

          })
          .catch((err) => {
            this.errors = err.errors;
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.user={
        first_name:'',
        middle_name:'',
        last_name:'',
        mobile:'',
        phone:'',
        address:'',
        username:'',
      }
    }
  }
}
</script>